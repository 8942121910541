.card {
  margin: 20px auto;
  border-spacing: 0px;
  padding: 20px;
  width: 200px;
  box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #ffffff;
  transition: all 0.5s;
}
.title {
  font-size: medium;
}
li,
a,
p {
  text-align: left;
  font-size: small;
}
.card div:hover {
  background-color: #f3f30a;
}
.card p:hover {
  background-color: #f3f30a;
}
